<template>
    <el-menu style="border:none" router :default-active="$route.path" unique-opened>
        <div v-for="(menu, idx) in menuItems" :key="idx">
            <el-submenu v-if="menu.children && menu.children.length >= 1" :index="menu.path">
                <template slot="title">
                    <i :class="menu.icon"></i>
                    <span slot="title">{{ menu.label }}</span>
                </template>
                <el-menu-item v-for="(sb, sid) in menu.children" :key="sid" :index="sb.path">{{sb.label}}</el-menu-item>
            </el-submenu>
            <el-menu-item v-else :index="menu.path">
                <i :class="menu.icon"></i>
                <span slot="title">{{ menu.label }}</span>
            </el-menu-item>
        </div>
    </el-menu>
</template>

<script>
    export default {
        name: 'AsideMenu',
        props: {
            menuItems: {
                type: Array,
                required: true
            }
        },
        mounted() {
            // console.log(this.menu)
        },
        methods: {
            handleRoute(menu) {
                let path = menu.url
                // 通过菜单URL跳转至指定路由
                this.$router.push("/" + path)
            }
        }
    };
</script>

<style scoped>
::v-deep .el-submenu .el-menu-item {
    min-width: 170px;
}
</style>
